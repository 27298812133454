<template>
  <v-row justify="center">
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      max-width="650px"
      v-if="resolutionScreen >= 500"
    >
      <!-- <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
      </template>-->

      <v-card>
        <v-card-title
          >{{ $t("sendtoemail.sendtomail") }}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">email</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height:350px">
          <br />
          <v-text-field
            prepend-icon="account_circle"
            dense
            outlined
            :label="$t('sendtoemail.to')"
            v-model="receiver"
            :disabled="createprogress"
            :error-messages="receiverError"
            required
            @input="$v.receiver.$touch()"
            @blur="$v.receiver.$touch()"
            :color="color.theme"
          ></v-text-field>
          <v-text-field
            prepend-icon="subject"
            dense
            outlined
            :label="$t('sendtoemail.subject')"
            v-model="subjectmail"
            :disabled="createprogress"
            required
            :color="color.theme"
          ></v-text-field>
          <v-chip
            class="ma-2"
            :color="color.theme"
            text-color="white"
            @click:close="close"
          >
            <v-avatar left>
              <v-icon>{{ filedata.file_icon[0] }}</v-icon>
            </v-avatar>
            {{ filedata.file_name }}
          </v-chip>
          <br />
          <br />
          <tiptap-vuetify
            v-model="content"
            :extensions="extensions"
            height="500px"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            @click="$emit('closesendmail'), cleardata(), $emit('openDrag')"
            :disabled="createprogress"
            >{{ $t("sendtoemail.buttonclose") }}</v-btn
          >
          <v-btn
            :color="color.theme"
            :dark="color.darkTheme"
            depressed
            @click="summitsendmail(), $emit('openDrag')"
            :loading="createprogress"
            >{{ $t("sendtoemail.buttonsend") }}</v-btn
          >
        </v-card-actions>
      </v-card>
      <!-- ขนาดหน้าจอโทรศัพท์ -->
      <!-- <v-card v-else>
        <v-card-title style="font-size: 15px;"
          >{{ $t("sendtoemail.sendtomail") }}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="28">email</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height:350px;padding:0 50px 20px">
          <br />
          <v-text-field
            style="font-size: 13px;"
            prepend-icon="account_circle"
            dense
            outlined
            :label="$t('sendtoemail.to')"
            v-model="receiver"
            :disabled="createprogress"
            :error-messages="receiverError"
            required
            @input="$v.receiver.$touch()"
            @blur="$v.receiver.$touch()"
            :color="color.theme"
          ></v-text-field>
          <v-text-field
            style="font-size: 13px;"
            prepend-icon="subject"
            dense
            outlined
            :label="$t('sendtoemail.subject')"
            v-model="subjectmail"
            :disabled="createprogress"
            required
            :color="color.theme"
          ></v-text-field>
          <v-chip
            style="font-size: 13px;"
            class="ma-2"
            :color="color.theme"
            text-color="white"
            @click:close="close"
          >
            <v-avatar left>
              <v-icon>{{ filedata.file_icon[0] }}</v-icon>
            </v-avatar>
            {{ filedata.file_name }}
          </v-chip>
          <br />
          <br />
          <tiptap-vuetify
            v-model="content"
            :extensions="extensions"
            height="500px"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="padding:12px">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="red"
            outlined
            @click="$emit('closesendmail'), cleardata(), $emit('openDrag')"
            :disabled="createprogress"
            >{{ $t("sendtoemail.buttonclose") }}</v-btn
          >
          <v-btn
            small
            :color="color.theme"
            :dark="color.darkTheme"
            depressed
            @click="summitsendmail(), $emit('openDrag')"
            :loading="createprogress"
            >{{ $t("sendtoemail.buttonsend") }}</v-btn
          >
        </v-card-actions>
      </v-card> -->
    </v-dialog>
    <!-- หน้าจอมือถือ -->
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
    >
      <v-card>
        <v-card-text class="pa-5">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPage">{{
                $t("sendtoemail.sendtomail")
              }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="$emit('closesendmail'), cleardata(), $emit('openDrag')"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text style="height: 400px;">
          <span :style="titleText">{{ $t("sendtoemail.to") }}</span>
          <v-text-field
            class="mt-1 textFieldClass"
            prepend-icon="account_circle"
            dense
            outlined
            v-model="receiver"
            :disabled="createprogress"
            :error-messages="receiverError"
            required
            @input="$v.receiver.$touch()"
            @blur="$v.receiver.$touch()"
            :color="color.theme"
          ></v-text-field>
          <span :style="titleText">{{ $t("sendtoemail.subject") }}</span>
          <v-text-field
            class="mt-1 textFieldClass"
            prepend-icon="subject"
            dense
            outlined
            v-model="subjectmail"
            :disabled="createprogress"
            required
            :color="color.theme"
          ></v-text-field>
          <v-chip
            style="font-size: 13px;"
            class="ma-2"
            :color="color.theme"
            text-color="white"
            @click:close="close"
          >
            <v-avatar left>
              <v-icon>{{ filedata.file_icon[0] }}</v-icon>
            </v-avatar>
            {{ filedata.file_name }}
          </v-chip>
          <br />
          <br />
          <tiptap-vuetify
            v-model="content"
            :extensions="extensions"
            height="500px"
          />
        </v-card-text>
        <div class="text-center my-4">
          <v-btn
            :style="btnAction"
            class="mr-6"
            height="40px"
            width="40%"
            color="red"
            outlined
            @click="$emit('closesendmail'), cleardata(), $emit('openDrag')"
            :disabled="createprogress"
            >{{ $t("sendtoemail.buttonclose") }}</v-btn
          >
          <v-btn
            height="40px"
            width="40%"
            class="elevation-0"
            :style="btnAction"
            :color="color.theme"
            :dark="color.darkTheme"
            @click="summitsendmail(), $emit('openDrag')"
            :loading="createprogress"
            >{{ $t("sendtoemail.buttonsend") }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  mixins: [validationMixin],
  validations: {
    receiver: { required, email },
  },
  components: { TiptapVuetify },
  props: ["show", "parentfolder", "filedata"],
  data: function() {
    return {
      filename: "",
      receiver: "",
      subjectmail: "",
      createprogress: false,
      content: "",
      extensions: [
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        HardBreak,
      ],
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          let filename = this.filedata.file_name.split(".");
          console.log(filename);
          let i;
          let namewithoutextension = "";
          for (i = 0; i < filename.length; i++) {
            if (i !== filename.length - 1) {
              namewithoutextension += filename[i];
            } else if (filename.length - 1 === 0) {
              namewithoutextension += filename[i];
            }
          }
          this.filename = namewithoutextension;

          if (this.filedata.file_type === "folder") {
            Toast.fire({
              icon: "warning",
              title: this.$t("sendtoemail.cannotsendfolder"),
            });
            this.$emit("closesendmail");
          }
          this.$emit("closeDrag");
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closesendmail");
        }
      },
    },
    receiverError() {
      const errors = [];
      if (!this.$v.receiver.$dirty) return errors;
      !this.$v.receiver.required &&
        errors.push(this.$t("sendtoemail.pleasefillemail"));
      !this.$v.receiver.email &&
        errors.push(this.$t("sendtoemail.invalidemailformat"));
      return errors;
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if(this.resolutionScreen >= 400) {
        return 600
      } else {
        return 346
      }
    }
  },
  methods: {
    cleardata() {
      this.$v.$reset();
      this.foldername = "";
      this.receiver = "";
      this.content = "";
      this.subjectmail = "";
      this.createprogress = false;
    },
    close() {
      alert("Chip close clicked");
    },
    async summitsendmail() {
      let payload = {
        account_id: this.dataAccountId,
        user_id: this.dataUsername,
        receiver_email: this.receiver,
        content_email: this.content,
        subject_email: this.subjectmail,
        file_id: this.filedata.file_id,
        folder_id: "",
      };

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.createprogress = true;
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/send_attachment",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              this.createprogress = false;
              Toast.fire({
                icon: "success",
                title: this.$t("sendtoemail.sendemailsuccess"),
              });
              this.$emit("closesendmail");
              this.$emit("updatedatafile");
              this.cleardata();
            } else {
              this.createprogress = false;
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            Toast.fire({
              icon: "error",
              title: this.$t("sendtoemail.error"),
            });
            this.createprogress = false;
          });
      }
    },
  },
};
</script>

<style>
header.v-sheet.theme--light.v-toolbar.v-toolbar--dense.v-toolbar--flat.grey.lighten-4 {
  background-color: #f5f5f5 !important;
}
header.v-sheet.theme--dark.v-toolbar.v-toolbar--dense.v-toolbar--flat.grey.lighten-4 {
  background-color: #333333 !important;
}
.v-input.textFieldClass input {
  font-size: 14px;
}
</style>
